enum ROUTE_NAMES {
  HOME = '/',
  PRODUCT = 'geographiczoneslug-countryslug-travelstyleslug-productid',
  COUNTRY = 'geographiczoneslug-countryslug',
  CONTINENT = 'geographiczoneslug',
  WHEN_WHERE_TO_GO = 'ou-et-quand-partir',
  INSPIRATION_VOYAGE = 'inspiration-voyage'
}

export default ROUTE_NAMES;
